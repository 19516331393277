<template>
  <v-container class="cw-price-cards container--fluid grey lighten-3">
    <v-row v-if="isBusinessLoan" class="cw-price-cards__actions">
      <v-col class="pt-2 pt-sm-0">
        <v-card-actions class="pa-0">
          <v-spacer/>
          <v-btn-toggle
            v-model="displayStyle"
            color="primary"
            @change="setStyle()"
          >
            <v-btn
              id="cw-price-cards__actions--limited"
              value="limited"
              @click="$eventLogger.clickEvent($event)"
            >
              <v-icon :color="isLimitedView ? 'primary' : 'grey darken-1'">
                view_column
              </v-icon>
            </v-btn>

            <v-btn
              id="cw-price-cards__actions--all"
              value="all"
              @click="$eventLogger.clickEvent($event)"
            >
              <translate>
                All offers
              </translate>
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-row justify="center">
      <cw-offer-price-card
        v-for="(offer, index) in listOfOffers"
        :key="offer.id"
        v-bind="{
          active: offerId === offer.id,
          offer,
          index,
          isBusinessLoan,
        }"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CwLoanOfferPriceCards',

  components: {
    'cw-offer-price-card': () => import('@shared/components/LoanOfferPriceCard'),
  },

  props: {
    isBusinessLoan: {
      type: Boolean,
      default: false,
    },

    offerId: {
      type: String,
      default: null,
    },

    offers: {
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    brand: '',
    selected: '#ffffff',
    displayStyle: 'limited',
    listOfOffers: [],
  }),

  computed: {
    ...mapGetters({
      product: 'application/getProduct',
    }),

    isLimitedView() {
      return this.displayStyle === 'limited';
    },

    limitedOffers() {
      const offers = [];

      offers.push(this.offers[0]);
      offers.push(this.offers[this.offers.length - 1]);

      if (this.offers.length >= 3) {
        offers.splice(1, 0, this.offers[Math.floor((this.offers.length - 1) / 2)]);
      }

      return offers;
    },
  },

  created() {
    this.$bus.$on('SELECTED_OFFER_ID__SET', id => this.setSelectedOfferId(id));
    this.setStyle(this.displayStyle);
  },

  methods: {
    ...mapActions({
      onImpressionClick: 'dataLayer/onImpressionClick',
    }),

    resetOfferId() {
      this.$bus.$emit('SELECTED_OFFER_ID__SET', null);
    },

    setSelectedOfferId(id) {
      if (!id || id === this.offerId) return;

      let event = 'productClick';
      if (this.product.loanType === 'businessInstalmentLoan') {
        event = 'productClickB2b';
      }
      this.$dataLayer.PushToECommerce({
        params: {
          action: event,
          formData: { id },
        },
      });

      this.onImpressionClick();
    },

    setStyle() {
      this.listOfOffers = this.displayStyle === 'limited' && this.isBusinessLoan
        ? this.limitedOffers
        : this.offers;

      if (this.offerId === null) return;

      const visibleOffers = this.listOfOffers.map(offer => offer.id);

      if (!visibleOffers.includes(this.offerId)) this.resetOfferId();
    },
  },
};
</script>

<style lang="scss">
#cw-application__businessLoanOffer {
  .v-stepper {
    overflow: visible;
  }
}
</style>

<style lang="scss" scoped>
.cw-price-cards {
  box-shadow:
    0 1px 0 0 rgba(0,0,0, 0.1),
    0 -1px 0 0 rgba(0,0,0, 0.1);
  padding: 16px 0 !important;

  @include mq($from: xs) {
    padding: 24px 0 !important;
  }

  @include mq($from: md) {
    padding: 32px 0 !important;
  }

  &__actions {
    position: sticky;
    top: 16px;
    z-index: 1;
  }
}

.price-card {
  &__container {
    max-width: 320px;
    min-width: 284px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    p {
      margin: 0 0 4px;
    }
  }

  &__card {
    max-width: 360px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    padding: 0 8px 8px;

    &.active {
      color: #fff;

      .price-card__title {
        border-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
</style>
